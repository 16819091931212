import React from "react";
import testimonialsFetchData from "@/data/domain/testimonials.json";
import TestimonialCard from "@/common/components/cards/testimonial-card/TestimonialCard";
import { v4 as uuidv4 } from "uuid";
import { LABELS } from "@/common/constants/constants";

import styles from "./Testimonials.module.scss";

const Testimonials = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTitle}>{LABELS.WHAT_PEOPLE_SAY}</div>
      <div className={styles.cardsWrapper}>
        {testimonialsFetchData.map((item) => {
          return (
            <div key={uuidv4()} className={styles.card}>
              <img
                src={"/icons/quotation-mark-icon.svg"}
                width={39}
                height={31}
                alt="quotation-mark-icon"
                loading="lazy"
              />
              <TestimonialCard
                title={item.name}
                description={item.message}
                icon={item.photo}
                altIcon={item.altIcon}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Testimonials;
