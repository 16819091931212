import React from "react";
import { LABELS } from "@/common/constants/constants";
import InputsSection from "@/common/components/inputs-section/InputsSection";

import styles from "./PersonalizingHome.module.scss";

const PersonalizingHome = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{LABELS.PERSONALIZING_EACH}</div>
      <InputsSection />
    </div>
  );
};

export default PersonalizingHome;
