import React from "react";
import BrowseJobs from "@/features/home-page/components/browse-jobs-section/BrowseJobs";
import WhyCompany from "@/features/home-page/components/why-company-section/WhyCompany";
import Testimonials from "@/features/home-page/components/testimonials-section/Testimonials";
import CustomBackground from "@/common/components/custom-background/home-background/CustomBackground";
import PersonalizingHome from "@/features/home-page/components/personalizing-section/PersonalizingHome";
import styles from "@/styles/page.module.scss";

const HomePage = () => {
  return (
    <div className={styles.homeWrapper}>
      <PersonalizingHome />
      <BrowseJobs />
      <WhyCompany />
      <Testimonials />
      <CustomBackground />
    </div>
  );
};

export default HomePage;
