import React from "react";
import { IBasicCard } from "@/common/types/Types";

import styles from "./BasicCard.module.scss";

const BasicCard = (props: IBasicCard) => {
  const { title, description, icon, altIcon } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <img src={icon} width={80} height={80} alt={altIcon} loading="lazy" />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default BasicCard;
