import HomePage from "@/common/components/home/HomePage";
import { PAGE_TITLE } from "@/common/constants/constants";
import Head from "next/head";

import React from "react";

const Home = () => {
  return (
    <>
      <Head>
        <title>{PAGE_TITLE.JOZY_JOBS}</title>
        <meta name="description" content="JozyJobs app" />
      </Head>
      <HomePage />
    </>
  );
};

export default Home;
