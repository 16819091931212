import React from "react";
import BrowseJobCard from "@/common/components/cards/browse-job-card/BrowseJobCard";
import browseJobsFetchData from "@/data/domain/browse-jobs.json";
import { v4 as uuidv4 } from "uuid";
import { PAGE_TITLE } from "@/common/constants/constants";

import styles from "./BrowseJobs.module.scss";
import Link from "next/link";

const BrowseJobs = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTitle}>{PAGE_TITLE.BROWSE_JOBS}</div>
      <div className={styles.cards}>
        {browseJobsFetchData.map((item) => {
          return (
            <Link
              href={{
                pathname: "/jobs",
                query: {
                  keyword: item.queryParam,
                },
              }}
              key={uuidv4()}
              className={styles.cardLink}
            >
              <BrowseJobCard
                icon={item.iconPath}
                altIcon={item.altIcon}
                title={item.title}
                description={item.description}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default BrowseJobs;
