import React from "react";
import whyCompanyFetchData from "@/data/domain/why-company.json";
import BasicCard from "@/common/components/cards/basic-card/BasicCard";
import { v4 as uuidv4 } from "uuid";
import { LABELS } from "@/common/constants/constants";

import styles from "./WhyCompany.module.scss";

const WhyCompany = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          {LABELS.ABOUT_COMPANY} {whyCompanyFetchData.companyName}
        </div>
        <div className={styles.contentDescription}>
          {whyCompanyFetchData.companyDescription}
        </div>
      </div>
      <div className={styles.sections}>
        {whyCompanyFetchData.sections.map((item) => {
          return (
            <div key={uuidv4()}>
              <BasicCard
                title={item.title}
                description={item.description}
                icon={item.icon}
                altIcon={item.altIcon}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WhyCompany;
