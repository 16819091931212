import React from "react";
import { IBasicCard } from "@/common/types/Types";

import styles from "./BrowseJobCard.module.scss";

const BrowseJobCard = (props: IBasicCard) => {
  const { icon, altIcon, title, description } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={icon} width={56} height={56} alt={altIcon} loading="lazy" />
        <div className={styles.cardDetails}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default BrowseJobCard;
