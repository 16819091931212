import React from "react";

import styles from "./CustomBackground.module.scss";

const CustomBackground = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.firstColor}></div>
      <div className={styles.secondColor}></div>
      <div className={styles.thirdColor}></div>
      <div className={styles.lastColor}></div>
    </div>
  );
};

export default CustomBackground;
